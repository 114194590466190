<template>
  <div class="pt-10">
    <v-img class="mx-auto" src="@/assets/img/logos/auth-logo.png" height="147px" contain></v-img>
    <v-form
      ref="form"
      class="login-form"
      autocomplete="off"
      spellcheck="false"
      autocorrect="off"
      @submit.prevent="doLogin"
    >
      <div class="mt-10 mb-10 px-0">
        <v-row>
          <v-col cols="12" class="mb-0 py-0">
            <base-text-field
              v-model="email"
              data-test-id="login-email"
              :rules="[rules.required, rules.email]"
              label="Correo electrónico"
              type="email"
              autofocus
              v-mask="resetEmail"
              @keydown.space.prevent
              @focus="errorMessage = ''"
            ></base-text-field>
          </v-col>
          <v-col cols="12" class="mt-0 py-0">
            <base-text-field
              data-test-id="login-password"
              v-model="password"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min(6)]"
              :type="passwordVisible ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              @click:append="passwordVisible = !passwordVisible"
              @focus="errorMessage = ''"
            ></base-text-field>
          </v-col>
          <v-fade-transition>
            <v-slide-x-transition leave-absolute>
              <v-col cols="12" class="py-0 mt-0 mb-3 text-center" v-if="errorMessage">
                <p class="error--text my-0">{{ errorMessage }}</p>
              </v-col>
            </v-slide-x-transition>
          </v-fade-transition>
          <div class="d-flex justify-end" style="cursor: pointer; width: 100%;">
            <router-link tag="p" class="login-bold-text pr-3 blue--text darken-2" :to="{name: 'RecoverPassword'}">
              ¿Olvidaste tu contraseña?
            </router-link>
          </div>
          <v-col class="botton-usamedic mb-5" cols="12">
            <base-button :loading="loading" class="full-width" type="submit">
              Ingresar
            </base-button>
          </v-col>
          <v-col cols="12" sm="9" class="mx-auto d-flex justify-center">
            <p class="login-text mb-0">Ó también puedes continuar con</p>
          </v-col>
          <v-col cols="12" sm="9" class="mx-auto d-flex justify-center">
            <v-card
              class="social-icon d-flex justify-center align-center mx-5"
              height="54px"
              width="84px"
              :href="'https://www.facebook.com/v3.2/dialog/oauth?client_id=318353502592358&redirect_uri='+baseUrl+'auth/facebook/callback&state=4IysbVChl9kt3zZIGYirbKIeccwjUgdv&return_scopes=true&scope=email&ret=login&fbapp_pres=0&logger_id=8db30071-8fce-4cb3-a6c5-3e449d345b73&shared_id=TODO_SHARED_ID&tp=unspecified&cbt=1606247747955&ext=1606251356&hash=AebNulAFtAJjW3KBI_Q'"
            >
              <v-img max-height="26px" max-width="26px" width="26px" height="26px"
                     src="@/assets/img/auth/facebook-icon.png"/>
            </v-card>
            <v-card
              class="social-icon d-flex justify-center align-center mx-5"
              height="54px"
              width="84px"
              :href="'https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=196945911140-b14alh1ji1nlclj91s91af20krkapq7t.apps.googleusercontent.com&redirect_uri='+baseUrl+'auth/google/callback&scope=openid%20profile%20email'"
            >
              <v-img max-height="26px" max-width="26px" src="@/assets/img/auth/google-icon.png"/>
            </v-card>
            <!--            <v-card-->
            <!--              class="social-icon d-flex justify-center align-center"-->
            <!--              height="54px"-->
            <!--              width="84px"-->
            <!--              href="https://appleid.apple.com/auth/authorize?response_type=code&response_mode=query&client_id=app.usamedic&redirect_uri=https://banqueo.usamedic.app/auth/apple/callback"-->
            <!--            >-->
            <!--              <v-img max-height="30px" max-width="26px" src="@/assets/img/auth/apple-icon.png"/>-->
            <!--            </v-card>-->
          </v-col>
          <v-col cols="12" sm="9" class="mx-auto d-flex justify-center align-center">
            <p class="login-text mb-0 mx-2">¿No tienes cuenta?</p>
            <p style="cursor: pointer;" class="mb-0 mx-2 login-bold-text" @click="$router.push({name: 'Registry'})">
              Registrate</p>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <ConfirmModal ref="authConfirm"/>
  </div>
</template>

<script>
import BaseButton from '@/components/auth/BaseButton'
import BaseTextField from '@/components/auth/BaseTextField'
import { mapActions } from 'vuex'
import { email, minLength, required } from '@/utils/rules'
import ConfirmModal from '@/components/auth/ConfirmModal'

export default {
  data () {
    return {
      baseUrl: 'https://flashcards.usamedic.app/',
      passwordVisible: false,
      password: '',
      email: '',
      errorMessage: '',
      loading: false,
      rules: {
        required,
        min: minLength,
        email
      }
    }
  },
  components: {
    BaseButton,
    BaseTextField,
    ConfirmModal
  },
  methods: {
    ...mapActions(['login', 'logout', 'sendLoginSignal']),
    async doLogin () {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          this.email = this.email.toLowerCase().trim()
          const payload = { username: this.email, password: this.password }
          await this.login(payload)
          await this.$router.push({ name: 'Inicio' })
          await this.sendLoginSignal()
          await this.startUniqueSessionListener()
        } catch (e) {
          const message = e.response.data.non_field_errors[0] || 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos'
          await this.$refs.authConfirm.open({
            type: 'error',
            title: '¡Ups!',
            message,
            acceptText: 'Confirmar'
          })
        } finally {
          this.loading = false
          this.$root.$loading.close()
        }
      }
    },
    resetEmail (value) {
      const email = value.replace(' ', '')
      return [...email]
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  color: linear-gradient(90deg, rgba(31, 193, 208, 1) 0%, rgba(31, 193, 208, 1) 5%, rgba(4, 128, 218, 1) 90%);
  background: linear-gradient(90deg, rgba(31, 193, 208, 1) 0%, rgba(31, 193, 208, 1) 5%, rgba(4, 128, 218, 1) 90%);
}

.texto-header {
  font-size: 23px;
}

.transparent {
  background-color: transparent !important
}

.left-arrow {
  margin-left: -25vw;
  color: transparent;
  margin-bottom: 20px;
}

.left-arrow {

  &__white {
    display: flex;
  }

  &__black {
    display: none;
  }
}

.login-form {
  max-width: 448px;
  margin: auto;
}

.social-icon {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08) !important;
  border-radius: 10px;
  cursor: pointer;
}

.error--text {
  font-size: 12px;
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}

@media (max-width: 900px) {
  .left-arrow {
    margin-left: 38px;
    margin-top: 20px;
    color: #0480DA;
    min-width: 40px !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 0px;
    margin-bottom: 20px;

    &__white {
      display: none;
    }

    &__black {
      display: flex;
    }
  }
}
</style>
